import type { CartItem } from '#types/cart'
import type { LanguageCode } from '#types/locale'
import type { ProductAttributeType } from '#types/product'
import type { Order } from '#types/components/checkout/order'
import type { Product } from '#root/api/clients/product/data-contracts'
import type {
  FitFinderBaseContext,
  FitFinderBaseObject,
  FitFinderContext,
  FitFinderContextInput,
  FitFinderObject,
  FitFinderOrderContext,
  FitFinderOrderObject,
  FitFinderProductContext,
  FitFinderProductContextInput,
  FitFinderProductObject,
  FitFinderSource,
} from '#types/plugin/fitFinder'

export const isFitFinderOrder = (input: FitFinderSource): input is Order => 'orderNumber' in input
export const isFitFinderProduct = (input: FitFinderSource): input is Product => 'variants' in input
const isFitFinderBaseContext = (input?: FitFinderContext): input is FitFinderBaseContext => input ? 'shopSessionId' in input && !('addtoCart' in input) : false
const isFitFinderProductContext = (input?: FitFinderContext): input is FitFinderProductContext => input ? 'addToCart' in input : false
export const isFitFinderProductContextInput = (input?: FitFinderContextInput): input is FitFinderProductContextInput => input ? 'addToCart' in input : false

const buildFitFinderCartItemObject = (item: CartItem) => ({
  gtin: '',
  itemId: item.productId,
  itemSubgroupId: item.masterId,
  price: item.price.proratedPrice,
  quantity: item.qty,
  size: item.variants.find(({ code }) => code === 'size')?.value,
})

const buildFitFinderBaseObject = ({
  locale,
  shopSessionId,
  userId,
}: FitFinderBaseContext): FitFinderBaseObject => ({
  userId,
  shopSessionId,
  shopCountry: getCountryCode(locale),
  shopLanguage: getLanguageCode(locale).toLowerCase() as LanguageCode,
})

const buildFitFinderOrderObject = (
  order: Order,
  context: FitFinderOrderContext
): FitFinderOrderObject => ({
  ...buildFitFinderBaseObject(context),
  currency: order.totals.currency,
  orderId: order.orderNumber,
  products: order.items.map(buildFitFinderCartItemObject),
})

const attrOptions = (product: Product, attrType: ProductAttributeType) =>
  product.attributes.find(({ type }) => type === attrType)?.options || []

const attrLabel = (product: Product, type: ProductAttributeType, attrValue: string) =>
  attrOptions(product, type).find(({ value }) => value === attrValue)?.label || ''

const buildFitFinderProductObject = (
  product: Product,
  context: FitFinderProductContext
): FitFinderProductObject => ({
  ...buildFitFinderBaseObject(context),
  allItemSubgroupIds: attrOptions(product, 'color').map(({ id }) => id) || [],
  currentItemSubgroupId:
    context.enableColorAsAttribute
    && context.attributeSelection.color
    && !product.id.endsWith(context.attributeSelection.color)
      ? `${product.id}${context.attributeSelection.color}`
      : product.id,
  mainImageLink: product.gallery?.[0].src || '',
  consent: {
    hasAnalyticsConsent() {
      return hasCookieConsent(['performanceAndAnalytics'])
    },
  },
  sizes: product.variants.map((variant) => {
    const variantAvailable = !!context.inventory.value?.variants[variant.id]?.quantity
    const sizeLabel = product.attributes
      .filter(({ type }) => type !== 'color')
      .map(({ type }) => attrLabel(product, type, variant.attributes[type]!))
      .join('/')

    return {
      value: sizeLabel,
      isAvailable: variantAvailable ?? variant.buyable
    }
  }) || [],
  operations: {
    getAddToCartBtn: context.getAddToCartBtn,
    getSizeChartLink: context.getSizeChartLink,
    async addToCart(productId, size) {
      this.selectSize(productId, size)
      context.addToCart({
        extendedGtmConfig: {
          list: 'Fit Analytics',
          eventAction: 'Size Finder'
        }
      })
    },
    getCartItems() {
      return context.cartItems.value.map(buildFitFinderCartItemObject)
    },
    getCurrentSize() {
      return product.attributes
        .filter(({ type }) => type !== 'color')
        .map(({ type }) => attrLabel(product, type, context.attributeSelection[type]!))
        .join('/')
    },
    async selectSize(_productId, sizeLabel) {
      const sizeAttrs = product.attributes.filter(({ type }) => type !== 'color')

      sizeLabel.split('/').forEach((value, i) => {
        const { type } = sizeAttrs[i]
        const option = attrOptions(product, type).find(({ label }) => label === value)

        if (option)
          context.selectSize(type, option.value, option.label)
      })
    },
  }
})

export function buildFitFinderObject(context: FitFinderBaseContext, source?: never): FitFinderBaseObject
export function buildFitFinderObject(context: FitFinderOrderContext, source?: Order): FitFinderOrderObject
export function buildFitFinderObject(context: FitFinderOrderContext, source?: Order): FitFinderOrderObject
export function buildFitFinderObject(context: FitFinderProductContext, source?: Product): FitFinderProductObject
export function buildFitFinderObject(context: FitFinderContext, source?: FitFinderSource): FitFinderObject
export function buildFitFinderObject(context: FitFinderContext, source?: FitFinderSource): FitFinderObject {
  if (source && isFitFinderOrder(source))
    return buildFitFinderOrderObject(source, context)

  if (source && isFitFinderProduct(source) && isFitFinderProductContext(context))
    return buildFitFinderProductObject(source, context)

  if (!source && isFitFinderBaseContext(context))
    return buildFitFinderBaseObject(context)

  throw new Error('Invalid input provided for fit finder')
}
