import type { WatchStopHandle } from 'vue'
import type { Order } from '#types/components/checkout/order'
import type { Product } from '#root/api/clients/product/data-contracts'
import type {
  FitFinderContext,
  FitFinderContextInput,
  FitFinderObject,
  FitFinderOrderContextInput,
  FitFinderProductContextInput,
  FitFinderSource
} from '#types/plugin/fitFinder'

declare global {
  interface Window {
    fitFinder?: FitFinderObject
  }
}

export default () => {
  const locale = useLocale()
  const auth = useAuthStore()
  const { $cookieConsent } = useNuxtApp()
  const sessionId = useCookie('shopSessionId', { default: uuid })
  const { showFitFinder, configExternalApps: { fitFinder: scriptUrls } } = useFeatureFlags()

  let disconnectFitFinder: WatchStopHandle

  const scriptUrl = ref()
  const context = computed<FitFinderContext>(() => ({
    locale,
    userId: auth.consumerNo,
    shopSessionId: sessionId.value,
  }))

  useHead({
    script: [
      {
        src: scriptUrl
      }
    ]
  })

  function injectFitFinder(_source?: MaybeRef<Order>, sourceContext?: FitFinderOrderContextInput): void
  function injectFitFinder(_source?: MaybeRef<Product>, sourceContext?: FitFinderProductContextInput): void
  function injectFitFinder(_source?: MaybeRef<FitFinderSource>, sourceContext?: FitFinderContext): void
  function injectFitFinder(_source?: MaybeRef<FitFinderSource>, sourceContext?: FitFinderContextInput) {
    if (import.meta.server || !showFitFinder)
      return

    if (!$cookieConsent?.value.performanceAndAnalytics) {
      const unwatchCookieConsent = watchOnce($cookieConsent, () => {
        injectFitFinder(_source as MaybeRef<FitFinderSource>, sourceContext as FitFinderContext)
      })

      onUnmounted(unwatchCookieConsent)
      onDeactivated(unwatchCookieConsent)

      return
    }

    const source = toValue(_source)

    if ((!source && !sourceContext) || (source && isFitFinderProduct(source)))
      scriptUrl.value = scriptUrls?.pdp
    if (source && isFitFinderOrder(source))
      scriptUrl.value = scriptUrls?.orderConfirmation

    const watchedContextValues = isFitFinderProductContextInput(sourceContext)
      ? [sourceContext.attributeSelection, sourceContext.cartItems]
      : []

    disconnectFitFinder = watch([_source, context, ...watchedContextValues], () => {
      window.fitFinder = buildFitFinderObject({
        ...context.value,
        ...(sourceContext || {}),
      }, toValue(_source))
    }, {
      immediate: true,
    })
  }

  onUnmounted(() => {
    disconnectFitFinder?.()
  })

  onDeactivated(() => {
    disconnectFitFinder?.()
  })

  return {
    injectFitFinder
  }
}
